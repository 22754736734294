<template>
	<div class="type">
		<frame :top1="top1" :top2="top2">
			<lists :titles='titles' :flag='flag' :contentC='datas' :list='list' @add='refresh()' @del='refresh()'>
				<div class="title">
					<div class="listcont">
						<div class="shuai" v-if="typeFlag==1">
						<div class="selname">商家类型:</div>
						<Select  placeholder="请选择" class="sel">
												<!-- <Option   value="">全部</Option> -->
						<Option  :value="item.storeid" v-for='item,index in selects' :key='index' @click.native="shopType(item.storeid)">{{item.s_name}}</Option>
						 </Select>
						</div>	
						<div class="shuai" v-if="typeFlag==1||typeFlag==2">
						<div class="selname">店铺类型:</div>
						<Select  placeholder="请选择" class="sel">
												<!-- <Option   value="">全部</Option> -->
						<Option  :value="item.storeid" v-for='item,index in selectp' :key='index' @click.native="storeidType(item.storeid)">{{item.s_name}}</Option>
						 </Select>
						</div>	
						<div class="input">
							<Button type="success" @click='search(xstoreid)'>搜索</Button>
						</div>
					</div>
				
					<div class="add">
						<el-button type="warning" plain @click='addType()'>添加</el-button>
					</div>
				</div>
			</lists>
		</frame>
		<page :num='num'></page>
		<Modal v-model="modal1" title="添加类型" @on-ok="ok" @on-cancel="cancel">
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
				<FormItem label="类型名称" prop="name">
					<Input v-model="formValidate.name" placeholder="请输入名称"></Input>
				</FormItem>
				<FormItem label="所属店铺" prop="city" v-if='typeFlag!=3'>
					<Select v-model="formValidate.city" placeholder="请选择">
						<Option :value="item.storeid" v-for='item,index in list' :key='index'>{{item.s_name}}</Option>
					</Select>
				</FormItem>
			</Form>
		</Modal>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import lists from './componType/Table.vue';
	import page from './componList/Page.vue';
	export default {
		name: 'type',
		data() {
			return {
				top1: '4-4',
				top2: ['4'],
				titles: null,
				flag: 1,
				datas: null,
				tabbar: ['全部商品', '已上架', '未上架', '待审核', '未通过'],
				num: 100,
				page: 1,
				limit: 10,
				modal1: false,
				selectp:'',
				selects:'',
				xstoreid:'',
				formValidate: {
					name: '',
					city: ''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				typeFlag: null,
				list:null
			}
		},
		components: {
			frame,
			lists,
			page
		},
		created() {
			this.typeFlag = this.$storage.getLocal('type');
			let token = this.$storage.getLocal('token');
			if(this.$storage.getLocal('type')!==3){
				this.titles = ['类型名称', '所属店铺', '规格数量', '设置', '操作']
			}else{
				this.titles = ['类型名称', '规格数量', '设置', '操作']
			}
			
			this.$request.getNextSelectList({token}).then(res => {
				if (res.code == 0) {
					this.list = res.data;
				}
			})
			
			this.$request.selGoodsClassData({
				token,
				limit: this.limit,
				page: this.page,
				type: this.$storage.getLocal('type'),
				storeid:''
			}).then(res => {
				if (res.code == 0) {
					this.datas = res.data.data;
					this.num = res.data.count;
				}
			})
			if(this.typeFlag=='1')
			{
				this.$request.getMerchantData({token}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selects = r.data;
						console.log(r)
				
					}
				})
			}else if(this.typeFlag=='2')
			{
				this.$request.getStoreDataList({token,pstoreid:'',login_type:this.$storage.getLocal('type')}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selectp = r.data;
						console.log(r)
					}
				})
			}
		
		},
		methods: {
			addType() {
				// console.log("s")
				this.modal1 = true
			},
			ok() {
				let token = this.$storage.getLocal('token');
				// console.log(this.formValidate)
				this.$refs['formValidate'].validate((valid) => {
					if (valid) {
						this.$request.goodsClassAdd({
							token,
							name: this.formValidate.name,
							type: this.typeFlag,
							id: this.formValidate.city
						}).then(res => {
							if (res.code == 0) {
								this.$Message.success('添加成功!');
								this.refresh();
								this.$refs['formValidate'].resetFields();
							}
						})
					} else {
						this.$Message.error('添加失败请输入名称！！！');
					}
				})
			},
			cancel() {
				this.$Message.info('取消添加');
			},
			shopType(value){
				console.log(111)
				let token = this.$storage.getLocal('token');
				this.$request.getStoreDataList({token,pstoreid:value,login_type:this.$storage.getLocal('type')}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selectp = r.data;
						console.log(r)
					}
				})
			},
			storeidType(value)
			{
				this.xstoreid=value
			},
			search(value)
			{
				console.log(value)
				let token = this.$storage.getLocal('token');
				this.$request.selGoodsClassData({
					token,
					limit: this.limit,
					page: this.page,
					type: this.$storage.getLocal('type'),
					storeid:value
					
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data;
						this.num = res.data.count;
					}
				})
			},
			refresh() {
				let token = this.$storage.getLocal('token');
				this.$request.selGoodsClassData({
					token,
					limit: this.limit,
					page: this.page,
					type: this.$storage.getLocal('type')
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data;
						this.num = res.data.count;
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.title {
		width: 88%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
	}

	.add {
		height: 50px;
	}
	.listcont
	{
		display: flex;
		}
	.shuai
	{
		display: flex;
		/* border:1px solid black */
	}
	.selname
	{
		width: 90%;
	}
	.sel
	{
		margin-left: 10px;
		margin-top: 5px;
	}
</style>
