<template>
	<div class="list">
		<Card style='margin-left: 0px'>
			<div style="height: 600px;">
				
				<div class="content">
					<div id="contents">
						<div class="cont">
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==2">
									<td>{{item2.specname}}</td>
									<td>{{item2.gcname}}</td>
									<td>{{item2.is_checked==1?'唯一':(item2.is_checked==2?'单选':'多选')}}</td>
									<td>{{item2.specval}}</td>
									<td class="oper">
										<div class="com" @click='addType(item2.gsid,item2.specname)'>编辑</div>
										<div class="del" @click="del(item2.gsid)">删除</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Card>
		
		
		
		
		
		
		
		
		
		
		<Modal v-model="modal20" title="添加" @on-ok="ok3" @on-cancel="cancel3">
			<Form ref="formValidate1" :model="formValidate2" :rules="ruleValidate2" :label-width="80">
				<FormItem label="规格名称" prop="name">
					<Input v-model="formValidate2.name" placeholder="请输入名称"></Input>
				</FormItem>
				<FormItem label="属性是否可选">
					<RadioGroup v-model="formValidate2.gender">
						<Radio label="1">唯一属性</Radio>
						<Radio label="2">单选属性</Radio>
						<Radio label="3">复选属性</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
			<div>
				选择"单选/复选属性"时，可以对商品该属性设置多个值，
				同时还能对不同属性值指定不同的价格加价，用户购买商品时需要选定具体的属性值。
				选择"唯一属性"时，商品的该属性值只能设置一个值，用户只能查看该值
			</div>
			<Button type="success" @click='newlyOk1()'>新增</Button>
			<div class="content">
				<div id="contents">
					<div class="cont" style="text-align: center;">
						<table>
							<tr>
								<td class='titNa' v-for="item4,index4 in newlydata" :key='index4'>{{item4}}</td>
							</tr>
							<tr v-for="item3,index3 in kind1" :key='index3'>
								<td><Input ref='input' placeholder="请输入" :value='kind1[index3].gsvname'></Input></td>
								<td class="oper">
									<div class="del" @click="delKind1(item3.index3)">删除</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</Modal>
		
		
		
		
		
		
	</div>
</template>

<script>
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			},
			list: {
				type: Array,
			},
			gcid: {
				type: Number,
			}
		},
		data() {
			return {
				social: [],
				dialogVisible: false,
				modal1: false,
				modal11: false,
				formValidate: {
					name: '',
					city: ''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				id: null,
				typeFlag: null,
				modal10: false,
				
				
				
				
				
				
				
				newlydata: ['属性名称', '操作'],
				modal20: false,
				formValidate2: {
					name: '',
					gender: '1'
				},
				ruleValidate2: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				kind1: [],
				gsid: null
			}
		},
		created() {
			
		},
		components: {
			
		},
		methods: {
			addType(id,name){
				this.gsid = id;
				this.modal20 = true;
				// this.$emit('addrenewal',id);
				this.formValidate2.name = name
				let token = this.$storage.getLocal('token');
				 this.$request.selGoodsSpecInfo({
					token,
					id
				}).then(res => {
					if(res.code == 0) {
						// console.log(res.data.specname);
						this.kind1 = res.data.specval
						
						this.ruleValidate2.name = res.data.specname
						this.ruleValidate2.gender = res.data.is_checked
					}
					
				})
			},
			cancel3(){
				this.$Message.info('取消编辑');
			},//取消
			ok3(){
				var addEnd = [];
				for (var i = 0; i < this.$refs.input.length; i++) {
					addEnd.push(this.$refs.input[i].currentValue)
				}
				// console.log(addEnd)
				let token = this.$storage.getLocal('token');
				
				// let token = this.$storage.getLocal('token');
				this.$refs['formValidate1'].validate((valid) => {
					if (valid) {
						this.$request.upGoodsSpecData({
							token,
							specval: addEnd,
							name: this.formValidate2.name,
							gcid: this.gcid,
							gsid: this.gsid,
							checked: this.formValidate2.gender
						}).then(res => {
							if(res.code==0){
								this.$emit('del','ok')
							}
						})
					} else {
						this.$Message.error('编辑失败请输入名称！！！');
					}
				})
			},//成功
			newlyOk1(){
				
			},//新增
			delKind1(){
				
			},//删除	
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			del(id) { //删除
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					this.$request.delGoodsSpecData({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$notify({
								title: '成功',
								message: '删除成功',
								type: 'success'
							});
							this.$emit('del','ok')
						} else {
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
		}
	}
</script>

<style scoped="scoped">
	table {
		width: 100%;
		background: #eeeeee;
		text-align: center;
	}
	
	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}
	
	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}
	
	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}
	
</style>
