<template>
	
	<div class="list">
		
		<Modal v-model="modal11" fullscreen title="新增属性规格">
			<Button type="success" @click='newly()'>新增</Button>
			<addtable :titles="addlist" :contentC="specList" :flag='2' @del='renewal()' @addrenewal='addrenewal' :gcid='specListId'></addtable>
		</Modal>



		<Modal v-model="modal10" title="添加" @on-ok="ok2" @on-cancel="cancel2">
			<Form ref="formValidate1" :model="formValidate1" :rules="ruleValidate1" :label-width="80">
				<FormItem label="规格名称" prop="name">
					<Input v-model="formValidate1.name" placeholder="请输入名称"></Input>
				</FormItem>
				<FormItem label="属性是否可选">
					<RadioGroup v-model="formValidate1.gender">
						<Radio label="1">唯一属性</Radio>
						<Radio label="2">单选属性</Radio>
						<Radio label="3">复选属性</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
			<div>
				选择"单选/复选属性"时，可以对商品该属性设置多个值，
				同时还能对不同属性值指定不同的价格加价，用户购买商品时需要选定具体的属性值。
				选择"唯一属性"时，商品的该属性值只能设置一个值，用户只能查看该值
			</div>
			<Button type="success" @click='newlyOk()'>新增</Button>
			<div class="content">
				<div id="contents">
					<div class="cont" style="text-align: center;">
						<table>
							<tr>
								<td class='titNa' v-for="item,index in newlydata" :key='index'>{{item}}</td>
							</tr>
							<tr v-for="item2,index2 in kind" :key='index2' v-if="flag==1">
								<td><Input ref='input' placeholder="请输入"></Input></td>
								<td class="oper">
									<div class="del" @click="delKind(item2.index2)">删除</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</Modal>
		<!-- ///////////////////////////////////////////////////////////////////////// -->


		<Modal v-model="modal20" title="添加" @on-ok="ok3" @on-cancel="cancel3">
			<Form ref="formValidate1" :model="formValidate2" :rules="ruleValidate2" :label-width="80">
				<FormItem label="规格名称" prop="name">
					<Input v-model="formValidate2.names" placeholder="请输入名称"></Input>
				</FormItem>
				<FormItem label="属性是否可选">
					<RadioGroup v-model="formValidate2.gender">
						<Radio label="1">唯一属性</Radio>
						<Radio label="2">单选属性</Radio>
						<Radio label="3">复选属性</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
			<div>
				选择"单选/复选属性"时，可以对商品该属性设置多个值，
				同时还能对不同属性值指定不同的价格加价，用户购买商品时需要选定具体的属性值。
				选择"唯一属性"时，商品的该属性值只能设置一个值，用户只能查看该值
			</div>
			<Button type="success" @click='newlyOk1()'>新增</Button>
			<div class="content">
				<div id="contents">
					<div class="cont" style="text-align: center;">
						<table>
							<tr>
								<td class='titNa' v-for="item4,index4 in newlydata" :key='index4'>{{item4}}</td>
							</tr>
							<tr v-for="item3,index3 in kind1" :key='index3'>
								<td><Input ref='input' placeholder="请输入"></Input></td>
								<td class="oper">
									<div class="del" @click="delKind1(item3.index3)">删除</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</Modal>


		<!-- ///////////////////////////////////////////////////////////////////////// -->
		<Modal v-model="modal1" title="添加类型" @on-ok="ok" @on-cancel="cancel">
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
				<FormItem label="类型名称" prop="name">
					<Input v-model="formValidate.name" placeholder="请输入名称"></Input>
				</FormItem>
				<FormItem label="所属店铺" prop="city" v-if='typeFlag!=3'>
					<Select v-model="formValidate.city" placeholder="请选择">
						<Option :value="item.storeid" v-for='item,index in list' :key='index'>{{item.s_name}}</Option>
					</Select>
				</FormItem>
			</Form>
		</Modal>


		<Card style='margin-left: 200px'>
			<div style="height: 600px;">
				<div class="titles">类型</div>
				<div class="title">
					<slot></slot>
				</div>
				<div class="content">
					<div id="contents">
						<div class="cont">
							<table>									
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==1">
									<td>{{item2.gcname}}</td>
									<td v-if="typeFlag!==3">{{item2.store_name}}</td>
									<td>{{item2.count}}</td>
									<td>
										<div class="add" @click="addClass(item2.gcid)">添加规格属性</div>
									</td>
									<td class="oper">
										<!-- <div class="stte" @click="examine(item2.gid)">查看</div> -->
										<div class="com" @click='addType(item2.gcid,item2.gcname,item2.storeid)'>编辑</div>
										<div class="del" @click="del(item2.gcid)">删除</div>
									</td> 
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Card>
	</div>
</template>

<script>
	import addtable from './Addtable.vue';
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			},
			list: {
				type: Array,
			}
		},
		data() {
			return {
				names: {
					1: '',
					2: '',
					3: ''
				},
				social: [],
				dialogVisible: false,
				modal1: false,
				modal11: false,
				formValidate: {
					name: '',
					city: '',
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				formValidate1: {
					name: '',
					gender: '1',
					storeid: '',
				},
				ruleValidate1: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				id: null,
				typeFlag: null,
				modal10: false,
				addlist: ['规格名称', '所属类型', '规格是否可选', '规格值', '操作'],
				newlydata: ['属性名称', '操作'],
				specList: null,
				specListId: null,
				kind: ['黑色', '黑色', '黑色'],
				/////////////////////////////////////////////////////////////////////////add
				modal20: false,
				formValidate2: {
					name: '',
					gender: '1'
					
				},
				ruleValidate2: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				kind1: [],
			}
		},
		comments: {

		},
		created() {
			this.typeFlag = this.$storage.getLocal('type')
			if (this.$storage.getLocal('type') !== 3) {
				this.titles = ['类型名称', '规格数量', '设置', '操作']
			} else {
				this.titles = ['类型名称', '所属店铺', '规格数量', '设置', '操作']
			}
		},
		components: {
			addtable
		},
		methods: {
			addrenewal(id) {
				let token = this.$storage.getLocal('token');
				this.$request.selGoodsSpecInfo({
					token,
					id
				}).then(res => {
					if (res.code == 0) {
						// console.log(res.data.specval);
						this.modal20 = true;
						this.ruleValidate2.name = res.data.gsname
						// console.log(res.data.specval.length)
						// console.log(this.$refs.input)
						// this.ruleValidate2.gender = res.data.is_checked
						// this.kind1 = res.data.specval
						// // console.log(res.data.specvat)
						// for (var i = 0; i < this.$refs.input.length; i++) {
						// 	// console.log(this.$refs.input[i])
						// 	this.$refs.input[i].value = res.data.specval[i];
						// }
						// setTimeout(function(){
						// },2000)
					}

				})
			},
			cancel3() {
				this.$Message.info('取消编辑');
			}, //取消
			ok3() {
				// var addEnd = [];
				// for (var i = 0; i < this.$refs.input.length; i++) {
				// 	addEnd.push(this.$refs.input[i].currentValue)
				// }
				// // console.log(addEnd)
				// let token = this.$storage.getLocal('token');

				// // let token = this.$storage.getLocal('token');
				// this.$refs['formValidate1'].validate((valid) => {
				// 	if (valid) {
				// 		// ['token','gcid','specval','is_checked','gsname'];
				// 		this.$request.addGoodsSpec({
				// 			token,
				// 			specval: addEnd,
				// 			name: this.formValidate1.name,
				// 			id: this.specListId,
				// 			checked: this.formValidate1.gender
				// 		}).then(res => {
				// 			// console.log(res)
				// 			if (res.code == 0) {
				// 				this.$Message.success('添加成功!');
				// 				for (var i = 0; i < this.$refs.input.length; i++) {
				// 					this.$refs.input[i].currentValue = ''
				// 				}
				// 				this.$refs['formValidate1'].resetFields();
				// 				this.$request.selGoodsSpecList({
				// 					token,
				// 					id: this.specListId
				// 				}).then(res => {
				// 					if (res.code == 0) {
				// 						this.specList = res.data;
				// 					}
				// 				})
				// 			}
				// 		})
				// 	} else {
				// 		this.$Message.error('添加失败请输入名称！！！');
				// 	}
				// })
			}, //成功
			newlyOk1() {

			}, //新增
			delKind1() {

			}, //删除		





			//////////////////////////////////////////////////////////////////////////////////////
			delKind(index) {
				this.kind.splice(index, 1)
			},
			newlyOk() {
				this.kind.push('黑色')

			},
			newly() {
				this.modal10 = true;
			},
			renewal() {
				let token = this.$storage.getLocal('token');
				this.$request.selGoodsSpecList({
					token,
					id: this.specListId
				}).then(res => {
					if (res.code == 0) {
						this.specList = res.data;
					}
				})
			},
			ok2() {
				let token = this.$storage.getLocal('token');
				let type = this.$storage.getLocal('type');
				// console.log()
				// this.$refs['formValidate1'].validate((valid) => {
				// if (valid) {
				// ['token','gcid','specval','is_checked','gsname'];
				var addEnd = [];
				for (var i = 0; i < this.$refs.input.length; i++) {
					addEnd.push(this.$refs.input[i].currentValue)
				}
				this.$request.addGoodsSpec({
					token,
					type,
					specval: addEnd,
					name: this.formValidate1.name,
					id: this.specListId,
					storeid:this.formValidate1.storeid,
					checked: this.formValidate1.gender
				}).then(res => {
					// console.log(res)
					if (res.code == 0) {
						this.$Message.success('添加成功!');
						for (var i = 0; i < this.$refs.input.length; i++) {
							this.$refs.input[i].currentValue = ''
						}
						this.$refs['formValidate1'].resetFields();
						this.$request.selGoodsSpecList({
							token,
							id: this.specListId
						}).then(res => {
							if (res.code == 0) {
								this.specList = res.data;
							}
						})
					}
				})
				// } else {
				// this.$Message.error('添加失败请输入名称！！！');
				// }
				// })



				// this.$refs['formValidate'].validate((valid) => {
				// 	if (valid) {
				// 		this.$request.goodsClassUp({
				// 			token,
				// 			name: this.formValidate.name,
				// 			id: this.id,
				// 			storeid: this.formValidate.city,
				// 			type: this.typeFlag
				// 		}).then(res => {
				// 			if (res.code == 0) {
				// this.$Message.success('添加成功!');
				// 				this.$refs['formValidate'].resetFields();
				// 				this.$emit('del', 'ok');
				// 			}
				// 		})
				// 	} else {
				// 		this.$Message.error('修改失败请输入名称！！！');
				// 	}
				// })
			},
			cancel2() {
				this.$Message.info('取消添加');
			},
			addClass(id) {
				this.modal11 = true;
				let token = this.$storage.getLocal('token');
				this.specListId = id;
				this.$request.selGoodsSpecList({
					token,
					id
				}).then(res => {
					if (res.code == 0) {
						this.specList = res.data;
					}
				})
			},
			addType(id, name, storeid) {
				// console.log("s")
				this.modal1 = true
				this.id = id;
				this.formValidate.name = name;
				this.formValidate.city = storeid
			},
			ok() {
				let token = this.$storage.getLocal('token');
				this.$refs['formValidate'].validate((valid) => {
					if (valid) {
						this.$request.goodsClassUp({
							token,
							name: this.formValidate.name,
							id: this.id,
							storeid: this.formValidate.city,
							type: this.typeFlag
						}).then(res => {
							if (res.code == 0) {
								this.$Message.success('修改成功!');
								this.$refs['formValidate'].resetFields();
								this.$emit('del', 'ok');
							}
						})
					} else {
						this.$Message.error('修改失败请输入名称！！！');
					}
				})
			},
			cancel() {
				this.$Message.info('取消修改');
			},
			del(id) { //删除
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					console.log(id)
					this.$request.delGoodsClassData({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
						} else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			examine(id) { //查看
				this.dialogVisible = true
			}
		}
	}
</script>

<style scoped="scoped">
	table {
		width: 100%;
		background: #eeeeee;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}

	.add {
		color: #42B983;
	}

	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
</style>
